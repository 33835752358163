<template>
  <home-activity />
</template>

<script>
  import HomeActivity from '../components/HomeActivity'

  export default {
    name: 'Home',

    components: {
      HomeActivity,
    },

  }
</script>
