<template>
  <v-container>
    <v-row>
      <v-col cols="12">

        <p class="title">Search Uploads</p>

        <v-card>

          <v-card-title>
            Filter
          </v-card-title>

          <v-card-text>

            <v-row>
              <v-col cols="6" >
                <v-datetime-picker label="Select date and time" v-model="datetime" :time-picker-props="timeProps"  :date-picker-props="dateProps" :text-field-props="textFieldProps">
                  <template slot="dateIcon">
                    <v-icon>mdi-calendar</v-icon>
                  </template>
                  <template slot="timeIcon">
                    <v-icon>mdi-clock</v-icon>
                  </template>
                </v-datetime-picker>
              </v-col>
              <v-col cols="6">
                <v-select
                    v-model="type"
                    :items="items"
                    item-text="name"
                    item-value="value"
                    label="Object"
                ></v-select>
              </v-col>
            </v-row>

            <v-btn color="primary" block @click="getData">search</v-btn>
            <p class="mb-0 mt-1 caption text-center" v-if="type==='points'">*showing data points for {{$moment(datetime).add($moment(datetime).utcOffset(),'minutes').utc()}} ± 5 minutes</p>
            <p class="mb-0 mt-1 caption text-center" v-else-if="type==='files'">*showing file uploads for {{$moment(datetime).add($moment(datetime).utcOffset(),'minutes').utc()}} ± 24 hours</p>

          </v-card-text>

        </v-card>


<p>&nbsp;</p>


        <template v-if="headers && headers[0]">
          <v-data-table
              :loading="progress"
              :headers="headers"
              :items="table"
              sortBy="_TIMESTAMP"
              :items-per-page="50"
              :footer-props="{
                'items-per-page-options': [10,25,50,100]
              }"
              class="elevation-1"
              @click:row="getDetails"
          >

            <template v-slot:item._TIMESTAMP="{ item }">
                {{ $moment(item._TIMESTAMP).utc().format('YYYY-MM-DD HH:mm') }} UTC
            </template>

          </v-data-table>
        </template>

      </v-col>
    </v-row>

    <v-dialog
        v-model="dialog"
        scrollable
        persistent
        max-width="800"
    >
      <v-card>

        <v-toolbar
            elevation="0"
        >

          <v-toolbar-title>Related file uploads</v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn icon>
            <v-icon @click="dialog=false">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>

          <v-row v-if="details" class="mb-2">
            <v-col cols="6" >
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>Uploaded by</v-list-item-subtitle>
                  <v-list-item-title>{{ details.user }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="6">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>Date</v-list-item-subtitle>
                  <v-list-item-title>{{ $moment(details.timestamp).utc() }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>

          <template v-if="headers && headers[0]">
            <v-data-table
                :loading="progress2"
                :headers="headers2"
                :items="files"
                sortBy="_FILENAME"
                :items-per-page="15"
                class="elevation-1"
            >


              <template v-slot:item._URL="{ item }">
                <a :href="item._URL" download target="_blank" style="text-decoration: none;">
                <v-icon
                >
                  mdi-download
                </v-icon>
                </a>
              </template>


            </v-data-table>
          </template>


        </v-card-text>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>

import {db} from '../plugins/firebase'

export default {
  name: 'HomeActivity',

  data() {
    return {
      dialog: false,
      type: 'points',
      progress: false,
      progress2: false,
      headers: [],
      headers2: [
        { text: 'Filename', value: '_FILENAME' },
        { text: 'Bucket', value: '_FILETYPE' },
        { text: '', align: 'end', sortable: false, value: '_URL' },
      ],
      table: [],
      details: {},
      files: [],
      items: [
        { name: 'Data points', value: 'points' },
        { name: 'File uploads', value: 'files' },
      ],
      datetime: this.$moment(Date.now()).utc().format('YYYY-MM-DD HH:mm'),
      timeProps: {
        format: '24hr',

      },
      dateProps: {

      },
      textFieldProps: {
        suffix: 'UTC'
      },
    }
  },

  watch: {

  },

  methods: {

    getDetails(item) {

      this.dialog = true
      this.progress2 = true

      this.details = {}
      this.files = []

      let data = db.collection('UploadActivity').doc(item._ACTIVITY)

      data.get().then((doc) => {
        if (doc.exists) {
          this.details = doc.data()

          db.collection('UploadActivity').doc(item._ACTIVITY).collection('files')
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  // doc.data() is never undefined for query doc snapshots
                  this.files.push(doc.data())
                });
                this.progress2 = false
              })
              .catch((error) => {
                console.log("Error getting documents: ", error);
              });

          this.progress2 = false


        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      }).catch((error) => {
        console.log("Error getting document:", error);
      });

    },


    getData() {

      this.progress = true

      this.table = []
      let data = null

      if (this.type === 'points') {
        this.headers = [
          {
            text: 'Timestamp',
            align: 'start',
            value: '_TIMESTAMP',
          },
          { text: 'Bucket', value: '_FILETYPE' },
          { text: 'Filename', value: '_FILENAME' },
        ]

        const unixTime = this.$moment(this.datetime).add(this.$moment(this.datetime).utcOffset(),'minutes').unix()*1000
        console.error(unixTime)
        data = db.collectionGroup('logs').where('_TIMESTAMP', '<=', unixTime+300000).where('_TIMESTAMP', '>=', unixTime-300000)

      } else if (this.type === 'files') {
        this.headers = [
          {
            text: 'Timestamp',
            align: 'start',
            value: '_TIMESTAMP',
          },
          { text: 'Bucket', value: '_FILETYPE' },
          { text: 'Filename', value: '_FILENAME' },
        ]

        const unixTime = this.$moment(this.datetime).add(this.$moment(this.datetime).utcOffset(),'minutes').unix()*1000
        console.error(unixTime)
        data = db.collectionGroup('files').where('_TIMESTAMP', '<=', unixTime+86400000).where('_TIMESTAMP', '>=', unixTime-86400000)

      } else {
        this.headers = []
      }

      if (data) {
        data.get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.table.push(doc.data())
          });
          this.progress = false
        }).catch((error) => {
          console.log("Error getting documents:", error);
        });
      }

    }
  }

}
</script>


